import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';

const getCurrentYear = () => (new Date()).getFullYear();

export default () => {
  return (
    <Grid item md={12} className="footerContainer">
      <Grid container justify="center" alignItems="center">
        <Grid item xs="12" sm="12" lg="10">
          <Grid container justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} md={8}>
              <Grid container justify="flex-start" alignItems="center" className="footerLinksContainer">
                <Link to="/" className="footerLink">Home</Link>
                <Link to="/privacy" className="footerLink">Privacy Policy</Link>
                <Link to="/privacy#ca_rights" className="footerLink">CA Privacy Rights</Link>
                <Link to="/terms" className="footerLink">Terms of Service</Link>
                <a
                  href="https://www.facebook.com/seniorsavingslist/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footerLink"
                >
                  Facebook
                </a>
              </Grid>
              <Grid item md={11}>
                <p className="paragraph">
                  SeniorSavings is reader-supported. When you buy through links on our
                  site, we may earn an affiliate commission for those purchases. We
                  appreciate your support.
                </p>
              </Grid>
              <Grid item>
                <span className="copyright">
                  Copyright ©
                  &nbsp;
                  {getCurrentYear()}
                &nbsp; Senior Savings
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
