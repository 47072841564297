import React from 'react';
import { Grid } from '@material-ui/core';
import { element } from 'prop-types';
import Header from './header';
import Footer from './footer';
import ScrollTop from './ScrollToTop';


const Layout = ({ children }) => {
  return (
    <Grid className="layout" container justify="center" alignItems="center">
      <Header />
      <Grid item xs="12" sm="12" lg="11">
        {children}
        <ScrollTop />
      </Grid>
      <Footer />
    </Grid>
  );
};

Layout.propTypes = {
  children: element.isRequired,
};

export default Layout;
