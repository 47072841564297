import React from 'react';
import {
  Grid, AppBar, Toolbar, Hidden, IconButton, Drawer, Divider,
} from '@material-ui/core';
import { Link } from 'gatsby';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Menu from './menu';
import SeniorSavingsLogo from '../assets/images/SeniorSavings_Logo.png';

export default () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const renderLogo = () => (
    <Link className="link" to="/">
      <img className="headerLogo" src={SeniorSavingsLogo} alt="Senior Savings" />
    </Link>
  );


  return (
    <>
      <AppBar position="fixed" className="header">
        <Toolbar className="toolbar">
          <Grid item xs={12}>
            <Grid className="menuContainer" container justify="center" alignItems="center">
              <Grid item xs={2}>
                {renderLogo()}
              </Grid>
              <Grid item xs={10} className="alignRight">
                <Hidden smDown>
                  <Menu />
                </Hidden>
                <Hidden smUp>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon
                      edge="start"
                    />
                  </IconButton>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: 'drawerPaper',
        }}
      >
        <div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <Menu />
      </Drawer>
    </>
  );
};
