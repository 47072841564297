import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Button, Grid } from '@material-ui/core';
import { normalizePath } from '../utils/get-url-path';

export default ({ scrollTrigger }) => {
  const { wpMenu } = useStaticQuery(graphql`
      {
          wpMenu(slug: { eq: "main" }) {
              name
              menuItems {
                  nodes {
                      label
                      url
                      parentId
                      connectedNode {
                          node {
                              ... on WpContentNode {
                                  uri
                              }
                          }
                      }
                  }
              }
          }
      }
  `);

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <Grid className={scrollTrigger ? 'maxHeight' : ''} container justify="flex-end" alignItems="center">
      <div className="navContainer">
        {wpMenu.menuItems.nodes.map((menuItem) => {
          if (menuItem.parentId) {
            return null;
          }
          const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;
          const isBrowser = typeof window !== 'undefined';
          const isActivePath = isBrowser ? window.location.href.indexOf(`/${menuItem.label.toLowerCase()}/`) > -1 : false;
          const isRoot = isBrowser ? window.location.pathname === '/' && menuItem.label.toLowerCase() === 'home' : false;
          if (menuItem.label.toLowerCase() === 'sign up') {
            return '';
          }
          return (
            <Button
              key={menuItem.url}
              className={`navItem ${isActivePath || (isRoot) ? 'activeNav' : ''}`}
              href={normalizePath(path)}
            >
              {menuItem.label}
            </Button>
          );
        })}
      </div>
    </Grid>
  ) : null;
};
